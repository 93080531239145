import React, { useContext } from 'react';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';
import Image from 'next/image';

const Name = () => {
  const { student } = useContext(GlobalLoginContext);
  return (
    <div className={'inline-block flex items-center'}>
      <div className="h-[20px] w-[20px] overflow-hidden rounded-full border border-solid border-wk-border-3 bg-[#ffb9b9]">
        <Image
          className="object-cover"
          alt="header"
          src={student?.data?.HeadImageUrl || '/images/login/avatar.svg'}
          width={20}
          height={20}
        />
      </div>
      <span className="ml-2 max-w-[100px] truncate">{student?.fullName}</span>
    </div>
  );
};

export default Name;
