/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import cls from 'classnames';
import Link from 'next/link';
import { trace } from '@wk/wk-gatherer';
import useGTM from '@/hooks/useGtag';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'size'> {
  // 主题： 只设置 背景色 字色边框 字号边距不设置 不一样 目前ui无标准规范
  theme?:
    | 'default'
    | 'colorful'
    | 'red'
    | 'colorfulBorder'
    | 'while'
    | 'empty'
    | 'math'
    | 'mathBorder'
    | 'cnBorder'
    | 'english'
    | 'english-dashed'
    | 'landing';
  // 暂无按钮尺寸设计规范
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  className?: string;
  href?: string;
  target?: string;
  // 普通埋点名称
  traceName?: string;
  // ga4名称
  traceGa4Name?: string;
  traceData?: object;
  // seo 所有按钮默认p
  htmlLabel?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    theme = 'default',
    children,
    size = 'base',
    href,
    target,
    traceName,
    traceData,
    traceGa4Name,
    htmlLabel = 'p',
    ...restProps
  } = props;
  const sendDataToGTM = useGTM();
  // console.log(props.children, props, '---button');
  const baseButtonClasses = 'inline-flex justify-center items-center rounded-full select-none';
  // 为每一种按钮类型定义 Tailwind 类
  const buttonTypeClasses = {
    default: 'border border-wk-words-1 text-wk-words-1 hover:bg-wk-words-1  hover:text-white',
    // 用于自定义样式
    empty: '',
    colorful: `colorfulBtn bg-[220%,100%] bg-[100%_0%] text-white bgGradientColorful`,
    colorfulBorder: `
    colorfulBtn bg-[220%,100%] bg-[100%_0%] after:bg-[100%_0%] after:bg-[220%,100%] text-white relative bgGradientColorful
    border-2 border-white after:bgGradientColorful after:blur-[25px] after:absolute after:top-[40%] after:-z-10 after:block after:h-3/5 after:w-full after:rounded-full
  `,
    // red: 'text-white bg-wk-red-500 border-2 border-white bg-[linear-gradient(90deg,#FF1D43_0%,#FF3A3A4_9.98%,#FF765A_100%)]',
    red: 'text-white relative bg-[linear-gradient(90deg,#FF1D43_0%,#FF3A3A_49.98%,#FF765A_100%)] border-2 border-white after:bg-[linear-gradient(90deg,#FF1D43_0%,#FF3A3A_49.98%,#FF765A_100%)] after:blur-[25px] after:absolute after:top-[60%] after:-z-10  after:block  after:h-2/5 after:w-full after:rounded-full',
    while: 'bg-white text-wk-words-5',
    math: 'text-white relative bg-[linear-gradient(88.05deg,#2757FF_1.67%,#15B9FF_98.38%)] border-2 border-white after:bg-[linear-gradient(180deg,rgba(39,87,255,0.8)_0%,rgba(21,185,255,0.8)_100%)] after:blur-[25px] after:absolute after:top-[40%] after:-z-10  after:block  after:h-3/5 after:w-full after:rounded-full',
    mathBorder:
      'border-2 border-[#0092FF] hover:bg-[linear-gradient(88.05deg,#256AF2_1.67%,#14A9FF_98.38%)] hover:bg-clip-padding english-dashed-hover-text hover:border-transparent',
    cnBorder:
      'border-2 border-[#FF1D43] text-transparent hover:bg-[linear-gradient(89.62deg,#FF1D43_0.3%,#FF3A3A_53.07%,#FF765A_105.88%)] hover:bg-clip-padding english-dashed-hover-text hover:border-transparent',
    'english-dashed':
      'border-2 border-[#8642F8] hover:bg-[linear-gradient(88deg,#8642F8_1.68%,#C740E8_98.37%)] hover:bg-clip-padding english-dashed-hover-text hover:border-transparent',
    english:
      'text-white relative bg-[linear-gradient(88deg,#8642F8_1.68%,#C740E8_98.37%)] border-2 border-white after:bg-[linear-gradient(88deg,rgba(134,66,248,0.80)_35.47%,rgba(244,66,248,0.80)_98.37%)] after:blur-[25px] after:absolute after:top-[60%] after:-z-10  after:block  after:h-2/5 after:w-full after:rounded-full',
    landing: 'border-2 text-white hover:text-white',
  };

  const sizeClasses = {
    xs: '',
    sm: '',
    base: '',
    lg: '',
    xl: 'py-3 px-[30px]  lg:py-4 lg:px-12 xl:px-[60px] xl:py-4 xl:text-[28px] font-semibold text-xl xl:leading-normal leading-normal',
  };

  // 为什么px5不生效
  const buttonClasses = cls(baseButtonClasses, buttonTypeClasses[theme], sizeClasses[size], className);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (traceName) {
      const childArray = React.Children.toArray(children);
      const content = childArray
        .map((child) => {
          if (React.isValidElement(child)) {
            return child.props.children;
          }
          return child;
        })
        .join('');
      trace(traceName, { button_name: content, ...(traceData || {}) });
    }
    if (traceGa4Name) {
      // 目前只传递 traceName
      sendDataToGTM(traceGa4Name);
    }
    props.onClick && props.onClick(e);
  };
  const DynamicTag = htmlLabel as keyof JSX.IntrinsicElements;
  const button = (
    <button className={buttonClasses} {...restProps} onClick={handleClick}>
      <DynamicTag>{children}</DynamicTag>
    </button>
  );
  if (href) {
    return (
      <Link href={href} target={target}>
        {button}
      </Link>
    );
  }

  return button;
};

export default Button;
