import { StudentInfo } from '@/request/interface/login';
import useUserStore from '@/store/useUserStore';

const useReserveInfo = () => {
  const { user } = useUserStore((userState) => userState);
  const canReserveInfo = (user as StudentInfo)?.canTrialInfo || {};
  const { ENGLISH, CHINESE, MATH } = canReserveInfo;

  const isReserve = (subject: 'chinese' | 'english' | 'math') => {
    const canReserve = canReserveInfo[subject.toLocaleUpperCase() as keyof typeof canReserveInfo];
    return canReserve === false;
  };

  return {
    // 是否预约过全部科目
    isReserveAllSubject: ENGLISH === false && CHINESE === false && MATH === false,
    // 是否预约过中文
    isReserveChinese: CHINESE === false,
    // 是否预约过英文
    isReserveEnglish: ENGLISH === false,
    // 是否预约过数学
    isReserveMath: MATH === false,
    // 通过科目判断是否约过课
    isReserve,
  };
};

export default useReserveInfo;
