import React, { useContext } from 'react';
import cls from 'classnames';
import { MyContext } from '@/config/context-manager';

// *****文案说明*****
// strong 标签带彩色背景
// p 标签换行
// span 标签换行 pc 换行 移动端不换行 (文案不可控制 不换行 字多会隐藏 不会折行 ui)
// 有特殊要求 <br class="hide-pc" /> pc不换行 移动端换行 （尽量不使用）
//  注意如果遇到空格 需要在strong 前面加空格。
// 遇到bug 在ios14以下手机 彩色渐变 换行情况 下面的字会被不显示，解决办法 _strong inline-block 会有其他问题空格显示不了
export interface TitleProps {
  className?: string;
  children: React.ReactNode;
  innerHTML?: boolean;
  // 主题
  theme?: 'default' | 'colorful' | 'red' | 'math' | 'english';
  // 运行更改标签 seo 默认h2
  htmlLabel?: string;
}

const Title = ({ className, innerHTML = false, theme = 'default', htmlLabel = 'h2', children }: TitleProps) => {
  const { locale } = useContext(MyContext);
  const themeClasses = {
    default: '',
    colorful: '[&_strong]:linearGradient1 [&_strong]:inline-block ',
    math: '[&_strong]:linearGradientForMath [&_strong]:inline-block',
    red: '[&_strong]:linearGradientChinese [&_strong]:inline-block',
    english: '[&_strong]:linearGradientForEnglish [&_strong]:inline-block',
  };
  const classes = cls(
    'text-center mx-auto text-wk-words-1 lg:text-[34px] text-[28px] xl:text-5xl font-black xl:leading-normal lg:leading-normal leading-normal',
    'lg:[&_>span]:whitespace-nowrap lg:[&>_span]:inline-block',
    locale === 'zh' && 'font-sourceHanSans',
    themeClasses[theme],
    className,
  );

  const DynamicTag = htmlLabel as keyof JSX.IntrinsicElements;

  if (innerHTML) {
    return <DynamicTag className={classes} dangerouslySetInnerHTML={{ __html: children as string }} />;
  }
  return <DynamicTag className={classes}>{children}</DynamicTag>;
};

export default Title;
