import Warn from '@/components/Icon/Warn';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

const ToStuCenter = () => {
  const { handleGoToStuCenter } = useContext(GlobalLoginContext);
  const { t } = useTranslation(['common']);

  return (
    <div className="flex flex-col items-center">
      <Warn className="h-14 w-14" />
      <h1 className="mt-[24px]">{t('您已预约WuKong课程')}</h1>
      <div
        onClick={() => handleGoToStuCenter(true)}
        className="mt-[48px] flex h-[60px] w-full cursor-pointer items-center justify-center rounded-[10px] bg-[#f5f5fc] text-[16px] font-medium text-[#1b1f4d]"
      >
        {t('前往学员中心')}
      </div>
    </div>
  );
};

export default ToStuCenter;
