import Warn from '@/components/Icon/Warn';
import useReserveInfo from '@/hooks/useReserveInfo';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
// import { useRouter } from 'next/router';
import { FC } from 'react';

const subjectMap = {
  chinese: '约课改造-中文课',
  math: '约课改造-国际数学课',
  english: '约课改造-英文阅读写作课',
};

interface Props {
  subject: 'chinese' | 'math' | 'english';
}

const ReserveOtherSubject: FC<Props> = ({ subject }) => {
  const { t } = useTranslation(['common']);
  const { isReserveChinese, isReserveMath, isReserveEnglish } = useReserveInfo();
  const router = useRouter();

  const toReserve = (currentSubject: 'chinese' | 'math' | 'english') => {
    router.push(`/independent-appointment/?subject=${currentSubject}`);
    // const url = `/independent-appointment/?subject=${currentSubject}`;
    // window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col items-center">
      <Warn className="h-14 w-14" />
      <h1 className="mt-[24px]">
        {t('您已预约WuKong课程-科目', {
          subject: t(subjectMap[subject]),
        })}
      </h1>
      <div className="mt-[48px]">{t('试试其他课程！')}</div>
      {!isReserveChinese && (
        <div
          onClick={() => toReserve('chinese')}
          className="mt-[16px] flex h-[60px] w-full cursor-pointer items-center justify-center rounded-[10px] bg-[#f5f5fc] text-[16px] font-medium text-[#1b1f4d]"
        >
          {t('约课改造-中文课')}
        </div>
      )}
      {!isReserveMath && (
        <div
          onClick={() => toReserve('math')}
          className="mt-[16px] flex h-[60px] w-full cursor-pointer items-center justify-center rounded-[10px] bg-[#f5f5fc] text-[16px] font-medium text-[#1b1f4d]"
        >
          {t('约课改造-国际数学课')}
        </div>
      )}
      {!isReserveEnglish && (
        <div
          onClick={() => toReserve('english')}
          className="mt-[16px] flex h-[60px] w-full cursor-pointer items-center justify-center rounded-[10px] bg-[#f5f5fc] text-[16px] font-medium text-[#1b1f4d]"
        >
          {t('约课改造-英文阅读写作课')}
        </div>
      )}
    </div>
  );
};

export default ReserveOtherSubject;
