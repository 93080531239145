import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';
import { traceEvent } from '@wk/wk-gatherer';
import { getLoginReportL } from '@/utils/constants';
import router from 'next/router';

const LogOut = () => {
  const { t } = useTranslation('common');
  const { handleLogout } = useContext(GlobalLoginContext);
  return (
    <div
      onClick={() => {
        traceEvent({
          data: {
            _event: 'O_OfficialWebLogOut',
            payload: {
              l: getLoginReportL(),
              url: window.location.href,
              page_name: router.pathname,
              button_name: '退出登录',
              re_event: 'O_LoginButtonClick',
            },
          },
        });
        handleLogout();
      }}
      className="inline-block flex items-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M10.9066 4.54492H5.45459V15.454H10.9091"
          stroke="#1B1F4D"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6362 12.726L16.3635 9.99876L13.6362 7.27148"
          stroke="#1B1F4D"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.48486 9.99707H16.3637"
          stroke="#1B1F4D"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="ml-2">{t('退出登录')}</span>
    </div>
  );
};

export default LogOut;
