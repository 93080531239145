// eslint-disable-next-line import/no-cycle
import { request } from './index';
import { GetCountryRequest, GetCountryResponse } from './interface/common';

// 获取地区
// eslint-disable-next-line
export const getCountry = (params: GetCountryRequest) => {
  return request.post<GetCountryResponse, GetCountryResponse>(`/public/reserve/country/info`, params);
};

// 获取时间 public/timestamp
export const getTimestamp = () => {
  return request.get<number, number>(`/public/timestamp`);
};
