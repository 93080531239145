/**
 * 学习园地及旧官网页面使用，其他页面无需使用
 *
 */
import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
});

const Mobile: FC<{ children: ReactNode }> = ({ children }) => {
  return <MediaQuery maxWidth={1023}>{children}</MediaQuery>;
};

export default Mobile;
