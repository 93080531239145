import { create } from 'zustand';

interface ContactInfo {
  tele?: string;
  teleZh: string;
  nation?: string;
  enName?: string;
  city?: string;
}

export interface ContactState {
  contactInfo: ContactInfo;
  setContactInfo: (contactInfo: ContactInfo) => void;
}

const useContactInfo = create<ContactState>((set) => ({
  contactInfo: {
    enName: 'New Zealand',
    city: '奥克兰',
    nation: '新西兰',
    teleZh: '+64 98709184',
    tele: '+64 98717076',
  },
  setContactInfo: (contactInfo: ContactInfo) => set({ contactInfo }),
}));

export default useContactInfo;
