import dayjs from 'dayjs';
import * as Sentry from '@sentry/nextjs';

/**
 * 开启慢接口统计事务
 *
 * @param {*} requestConfig
 */
export const startTransaction = (requestConfig: any) => {
  const transaction = Sentry.startTransaction({
    name: `${requestConfig.method.toUpperCase()} ${requestConfig.url}`,
    op: '慢接口统计',
    description: '接口响应时间统计，仅统计900ms及以上慢接口',
  });
  // eslint-disable-next-line no-param-reassign
  requestConfig.metadata = { transaction, startTime: new Date().getTime() };
};

/**
 * 结束慢接口统计事务
 *
 * @param {*} res
 */
export const finishTransaction = (res: any) => {
  const { startTime, transaction } = res?.config?.metadata || {};
  const endTime = new Date().getTime();
  if (transaction && startTime && endTime - startTime >= 900) {
    transaction.setContext('http', {
      method: `${res.config.method.toUpperCase()}`,
      requestTime: dayjs(new Date(Number(startTime))).format('YYYY-MM-DD HH:mm:ss'),
      responseTime: `${endTime - startTime}ms`,
      requestURL: res.request.requestURL,
    });
    transaction.finish();
  }
};
