import { useCallback, useContext } from 'react';
import { gtag } from 'ga-gtag';
import { MyContext } from '@/config/context-manager';

const PREFIX_NAME = '';

const useGTM = (prefix?: string) => {
  const { kaamelGAAgree } = useContext(MyContext);

  const sendGTM = useCallback(
    (eventName: string, data?: Record<string, any>) => {
      if (kaamelGAAgree) {
        const prefixedEventName = `${prefix ?? PREFIX_NAME}${eventName}`;
        const eventData = { send_to: 'group1', event_category: prefixedEventName, ...data };
        gtag('event', prefixedEventName, eventData);
      }
    },
    [kaamelGAAgree],
  );

  return sendGTM;
};

export default useGTM;
