import React, { useContext, useState } from 'react';
import Button, { ButtonProps } from '@/components/Button';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';
import { CHINESE_COURSE_URL } from '@/utils/constants';
import { setTriggerEvent, setTriggerPvid } from '@/utils/token';
import { getBasicInfo } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import useReserveInfo from '@/hooks/useReserveInfo';
import useIsMobile from '@/hooks/useIsMobile';
import { objToQuery } from '@/utils/url';
import { checkIfGoToGS20 } from '@/utils/utils';
import Modal from '../Modal';
import ToStuCenter from '../IndependentAppointment/ToStuCenter';
import ReserveOtherSubject from '../IndependentAppointment/ReserveOtherSubject';

interface IProps extends ButtonProps {
  subject?: 'chinese' | 'english' | 'math';
  isBlob?: boolean;
}

const LeadButton: React.FC<IProps> = ({ children, ...restProps }) => {
  const { student } = useContext(GlobalLoginContext);
  const router = useRouter();
  const { isReserveAllSubject, isReserve } = useReserveInfo();
  const [stuModalVisible, setStuModalVisible] = useState<boolean>(false);
  const [otherSubjectModalVisible, setOtherSubjectModalVisible] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { subject } = restProps || {};

  const toReservePage = (reserveSubject?: 'chinese' | 'english' | 'math' | undefined) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const l = urlSearchParams.get('l') || '';
    const queryString = objToQuery({
      subject: reserveSubject || '',
      booking_triggerevent: restProps?.traceName || '',
      l,
    });
    if (checkIfGoToGS20(reserveSubject, student, queryString)) return;
    router.push(`/independent-appointment/${queryString}`);
  };

  const handleClick = () => {
    setTriggerPvid(getBasicInfo().pvid as string);
    setTriggerEvent(restProps?.traceName as string);

    if (restProps.isBlob) {
      window.open(CHINESE_COURSE_URL);
      return;
    }

    const rules = [
      /* 已登录，不带科目，没有约过所有科目 跳不带科目约课页 */
      {
        match: () => student && !subject && !isReserveAllSubject,
        action: () => toReservePage(),
      },
      /* 已登录，不带科目，约过所有科目 打开引导用户进入学员中心弹窗 */
      {
        match: () => student && !subject && isReserveAllSubject,
        action: () => setStuModalVisible(true),
      },
      /* 已登录，带科目，且当前科目未约过课，跳带科目约课页 */
      {
        match: () => student && subject && !isReserve(subject),
        action: () => toReservePage(subject),
      },
      /* 已登录，带科目，且当前科目约过课，其他科目未约过课，打开引导用户约其他科目弹窗 */
      {
        match: () => student && subject && isReserve(subject) && !isReserveAllSubject,
        action: () => setOtherSubjectModalVisible(true),
      },
      /* 已登录，带科目，且当前科目约过课，其他科目都约了课，打开引导用户进入学员中心弹窗 */
      {
        match: () => student && subject && isReserve(subject) && isReserveAllSubject,
        action: () => setStuModalVisible(true),
      },
      /* 未登录，带科目，跳带科目约课页 */
      {
        match: () => !student && subject,
        action: () => toReservePage(subject),
      },
      /* 未登录，不带科目，跳不带科目约课页 */
      {
        match: () => !student && !subject,
        action: () => toReservePage(),
      },
    ];

    const { action } = rules.find(({ match }) => match()) || {};
    action?.();
  };

  return (
    <>
      <Button onClick={handleClick} {...restProps}>
        {children}
      </Button>
      <Modal
        visible={stuModalVisible}
        handleClose={() => setStuModalVisible(false)}
        width={isMobile ? 'calc(100vw - 2.5rem)' : 452}
      >
        <ToStuCenter />
      </Modal>
      <Modal
        visible={otherSubjectModalVisible}
        handleClose={() => setOtherSubjectModalVisible(false)}
        width={isMobile ? 'calc(100vw - 2.5rem)' : 452}
      >
        <ReserveOtherSubject subject={subject as 'chinese' | 'math' | 'english'} />
      </Modal>
    </>
  );
};

export default LeadButton;
