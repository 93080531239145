import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import React, { CSSProperties, FC, ReactElement } from 'react';
import Image from 'next/image';
import ReturnImg from '@public/images/common/return.svg';
import CloseImg from '@public/images/common/icon_close.svg';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { isPC } from '@/utils/utils';
import styles from './style.module.scss';

interface Props {
  visible: boolean;
  handleClose?: () => void;
  handleReturn?: () => void;
  width?: number | string;
  contentStyle?: CSSProperties;
  children: React.ReactNode;
  className?: string;
  title?: string | ReactElement;
  modalStyles?: {
    mask?: CSSProperties;
    wrapper?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
    footer?: CSSProperties;
  };
}

interface ConfirmProps {
  content: string | ReactElement;
  okText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  width?: number | string;
}

const Modal: FC<Props> = ({
  visible,
  handleClose,
  handleReturn,
  children,
  width,
  contentStyle,
  className,
  modalStyles = {},
  title,
}) => {
  return (
    <Dialog
      className={classNames(styles.modal, className && className)}
      visible={visible}
      animation="zoom"
      maskAnimation="fade"
      width={width || 420}
      styles={modalStyles}
      // destroyOnClose
    >
      {!!handleReturn && (
        <div className={styles.returnImg} onClick={handleReturn}>
          <Image src={ReturnImg} alt="返回" />
        </div>
      )}
      {!!handleClose && (
        <div className={styles.closeImg} onClick={handleClose}>
          <Image src={CloseImg} alt="关闭" />
        </div>
      )}
      {!!title && <div className="absolute left-1/2 top-[18px] translate-x-[-50%]">{title}</div>}
      <div className={styles.content} style={contentStyle}>
        {children}
      </div>
    </Dialog>
  );
};

export const showConfirmModal = ({
  content,
  okText = '确定',
  cancelText = '取消',
  onConfirm,
  onCancel,
  width,
}: ConfirmProps) => {
  const root = document.createElement('div');
  const isPc = isPC();

  const coseModal = (
    <Modal
      visible={false}
      width={width || 340}
      contentStyle={{ padding: isPc ? '12px 8px 8px 8px' : '8px 4px 4px 4px' }}
    >
      {content}
      <footer className={styles.confirmFooter}>
        <div
          className={classNames(
            styles.confirmBtn,
            'h-[44px] w-[120px] border-[1px] border-solid border-[#1B1F4D] text-[15px] text-[#1B1F4D] hover:border-0 hover:bg-[#1B1F4D] hover:!text-white  lg:w-[136px] lg:text-[16px]',
          )}
        >
          {cancelText}
        </div>
        <div
          className={classNames(styles.confirmBtn, 'h-[44px] w-[120px] text-[15px] lg:w-[136px] lg:text-[16px]')}
          style={{ color: '#fff', background: 'linear-gradient(90deg, #FF5353 8.55%, #6D38FB 61.14%, #009DFF 100%)' }}
        >
          {okText}
        </div>
      </footer>
    </Modal>
  );

  const handleCancel = () => {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(coseModal, root);
    document.body.append(root);
    setTimeout(() => {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.unmountComponentAtNode(root);
      document.body.removeChild(root);
    }, 300);
  };

  const handleConfirm = () => {
    onConfirm?.();
    handleCancel();
  };

  const modal = (
    <Modal visible width={width || 340} contentStyle={{ padding: isPc ? '12px 8px 8px 8px' : '8px 4px 4px 4px' }}>
      {content}
      <footer className={styles.confirmFooter}>
        <div
          onClick={() => {
            onCancel?.();
            handleCancel();
          }}
          className={classNames(
            styles.confirmBtn,
            'h-[44px] w-[120px] border-[1px] border-solid border-[#1B1F4D] text-[15px] text-[#1B1F4D] hover:border-0 hover:bg-[#1B1F4D] hover:!text-white lg:w-[136px] lg:text-[16px]',
          )}
        >
          {cancelText}
        </div>
        <div
          onClick={handleConfirm}
          className={classNames(styles.confirmBtn, 'h-[44px] w-[120px] text-[15px] lg:w-[136px] lg:text-[16px]')}
          style={{ color: '#fff', background: 'linear-gradient(90deg, #FF5353 8.55%, #6D38FB 61.14%, #009DFF 100%)' }}
        >
          {okText}
        </div>
      </footer>
    </Modal>
  );

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(modal, root);
  document.body.append(root);
};

export default Modal;
