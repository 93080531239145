import React from 'react';

const Warn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4547"
      width="200"
      height="200"
      {...props}
    >
      <path
        d="M512 0.009a501.751 501.751 0 0 0-199.378 40.02 513.783 513.783 0 0 0-163.069 109.524A513.783 513.783 0 0 0 40.03 312.623 501.751 501.751 0 0 0 0.009 512c0 70.57 13.312 137.043 40.02 199.378a513.783 513.783 0 0 0 109.524 163.069 513.783 513.783 0 0 0 163.07 109.523A501.751 501.751 0 0 0 512 1023.991a501.751 501.751 0 0 0 199.378-40.02 513.783 513.783 0 0 0 163.069-109.524 513.783 513.783 0 0 0 109.566-163.07A501.751 501.751 0 0 0 1023.991 512a501.751 501.751 0 0 0-39.978-199.378 513.783 513.783 0 0 0-109.566-163.069A513.783 513.783 0 0 0 711.377 40.03 501.751 501.751 0 0 0 512 0.009z m0 804.551c-20.309 0-37.589-7.125-51.796-21.333a70.527 70.527 0 0 1-21.333-51.796c0-20.31 7.082-37.589 21.333-51.797 14.207-14.25 31.487-21.333 51.796-21.333s37.589 7.083 51.796 21.333c14.25 14.208 21.333 31.488 21.333 51.797s-7.082 37.546-21.333 51.796C549.59 797.435 532.31 804.56 512 804.56z m59.092-275.451c0 15.573-5.76 28.8-17.237 39.68-11.52 10.922-25.429 16.34-41.855 16.34s-30.378-5.418-41.813-16.34a52.65 52.65 0 0 1-17.28-39.68V267.695c0-15.53 5.76-28.757 17.238-39.68 11.52-10.88 25.429-16.298 41.855-16.298s30.378 5.419 41.813 16.299c11.52 10.922 17.28 24.149 17.28 39.679v261.414z"
        fill="#FE8102"
        p-id="4548"
      />
    </svg>
  );
};

export default Warn;
