import http, { AxiosResponse } from 'axios';
import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
// import Toast from '@/components/Toast';
import { finishTransaction } from '@/utils/sentry';
import { getAPIBaseUrl } from '@/utils/url';

declare module 'axios' {
  interface AxiosRequestConfig {
    // 跳过页面提醒 true 不展示
    skipErrorHandler?: boolean;
    // needAuth?: boolean;
    // 是否需要 签名加密 默认不需要
    signature?: boolean;
    // 是否需要 签名加密 时间戳
    timestamp?: number;
    // 是否返回错误信息, 需与skipErrorHandler配合使用
    // true 返回
    returnErrorMsg?: boolean;
  }
}

const responseSuccess = (res: AxiosResponse<any>) => {
  finishTransaction(res);

  return res.data;
};

const responseFail = (err: any) => {
  finishTransaction(err);
  let errorMsg = '未知错误';

  if (err?.response) {
    const { data = {} } = err.response;
    const { errors, message } = data;
    errorMsg = (errors && errors[Object.keys(errors)[0]]) || message || err.message || errorMsg;
  } else {
    errorMsg = err.message || errorMsg;
  }

  // 如果需要展示 Toast 且不是设置为跳过错误处理的请求
  if (!err.config?.skipErrorHandler) {
    // Toast.info(errorMsg);
  }

  if (!err.config?.returnErrorMsg) {
    // 总是返回Promise.reject，附上错误信息
    return Promise.reject(errorMsg);
  }
  throw new Error(`[Server Response Error]: ${errorMsg}`);
};

const configureRequest = (baseURL?: string) => {
  const instance = http.create({
    baseURL,
    timeout: 30e3,
  });

  instance.interceptors.request.use((requestConfig) => {
    try {
      console.log(123);
    } catch (error) {
      console.log(error);
    }
    const { headers, signature = false, data, url, method, timestamp } = requestConfig;
    if (signature && timestamp) {
      const bodyHash = Base64.stringify(sha256(JSON.stringify(data)));
      const methodNew = method?.toUpperCase();
      const request = `${methodNew}\n${url}\n\ntimestamp:${timestamp}\n\ntimestamp\n${bodyHash}`;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const signature = Base64.stringify(hmacSHA256(request, process.env.NEXT_PUBLIC_CLUE_S || ''));
      headers.common['Accept-Language'] = window?.location?.href?.includes?.('/zh/') ? 'zh-CN' : 'en-US';
      headers.signature = signature;
      headers.SignedHeaders = 'timestamp';
      headers.timestamp = timestamp;
    }
    return requestConfig;
  });

  instance.interceptors.response.use(
    (res) => {
      return responseSuccess(res);
    },
    (err) => {
      return responseFail(err);
    },
  );

  return instance;
};

// 使用定义好的configureRequest函数创建各个实例
export const request = configureRequest(getAPIBaseUrl(process.env.NEXT_PUBLIC_INTEGRATION_API_HOST));
export const gatewayRsRequest = configureRequest(getAPIBaseUrl(process.env.NEXT_PUBLIC_GATEWAY_RS_API_HOST));
export const requestStu = configureRequest(getAPIBaseUrl(process.env.NEXT_PUBLIC_STU_CENTER_API_HOST));
